/* Number Counter */

#numbers {
    /* background:#21ddfb; */
    /* background: linear-gradient(to right, #000000 , #DD0000, #FFCE00); */
    /* background: url(${"http://www.w3.org/2000/svg"}); */
    /* background-image: url('svgg.png'); */
    padding:100px 0px;
}
.numc {    
    background: #fff;
    height: 150px;
    width: 150px;
    padding-top: 50px;
    border-radius: 50%;
    font-size:40px;
    color:#21ddfb;
}
.numd {
    padding-top: 30px;
}