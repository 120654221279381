/* Overlay */

.ovl {
    opacity: 0;
    transition: 1s;
}

.ovl:hover {
    opacity: 7;
    background: rgb(0,0,0,0.6);
}

.card {
    box-shadow: 2px 5px 20px #777;
}