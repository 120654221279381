  .media {
    display: flex;
    /* align-items: flex-start; */
  }
  
  .media .content {
    flex: 1;
    padding: 10px;
  }
  
  @media (max-width: 750px) {
    .media {
      flex-direction: column;
    }
  }